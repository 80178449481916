.header-navbar{
    padding: 20px 10% 0px 10%;
    width: 80%;
    .language{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
        p{
            font-size: 16px;
        }
        button{
            font-size: 18px;
            text-decoration: none;
            color: black;
            font-weight: 800;
            opacity: 1;
            border: none;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .navbar{
        display: flex;
        width: 100%;
        justify-content: space-between;
        border-top: 4px solid black;
        padding: 20px 0;
        a{
            font-size: 30px;
            text-decoration: none;
            color: black;
            font-weight: 800;
            &:hover{
                opacity: 0.8;
            }
        }
        button{
            font-size: 24px;
            text-decoration: none;
            color: black;
            font-weight: 800;
            border: none;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .navbar-title{
        font-size: 4vw;
    }
}


@media (max-width: 1400px) {
    .header-navbar{
        padding: 20px 5% 0px 5%;
        width: 90%;
        .language{
            p{
                font-size: 14px;
            }
            button{
                font-size: 16px;
            }
        }
        .navbar{
            a{
                font-size: 20px;
            }
            button{
                font-size: 20px;
            }
        }
        .navbar-title{
            font-size: 40px;
        }
    }    
}

@media (max-width: 580px) {
    .header-navbar{
        padding: 20px 5% 0px 5%;
        width: 90%;
        .language{
            p{
                font-size: 12px;
            }
            button{
                font-size: 14px;
            }
        }
        .navbar{
            a{
                font-size: 14px;
            }
            button{
                font-size: 14px;
            }
        }
        .navbar-title{
            font-size: 25px;
        }
    }    
}