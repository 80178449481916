@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-dots{
    display: none !important;
}

.slick-arrow{
    display: none !important;
}

.windows-home{
    padding: 20px 10%;
    h2{
        font-size: 3.5vw;
        margin-block-start: 1em;
        margin-block-end: 1em;
    }
    .windows-home-title{
        display: grid;
        margin-top: 40px;
        border-top: 4px solid black;
        grid-template-columns: auto 350px;
        padding: 50px 0;
        height: 350px;
        justify-content: space-between;
        position: relative;
        grid-template-areas: "home-title-h  windows-home-title-img";
        .home-title-h{
            grid-area: home-title-h;
        }
        .windows-home-title-img{
            grid-area: windows-home-title-img;
        }
    }
    .windows-home-text{
        display: grid;
        gap: 5%;
        p{
            text-align: justify;
        }
        a{
            text-decoration: none;
            font-weight: 800;
            color: black;
        }
        span{
            letter-spacing: 5px; 
        }
    }
}


.windows-home-title-img {
    position: relative;
    width: 350px;
    height: 350px;
    height: auto;
    overflow: hidden;
}

.rotating-image {
    max-width: 350px;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease-in-out;
    opacity: 1;
}

.rotating-image.fade-out {
    opacity: 0;
}

.rotating-image.fade-in {
    opacity: 1;
}


@media (max-width: 1400px) {
    .windows-home{
        padding: 20px 5%;
        h2{
            font-size: 35px;
            margin-block-start: 1em;
            margin-block-end: 1em;
        }
        .windows-home-title{
            height: 250px;
            grid-template-columns: auto 250px;
            img{
                max-width: 250px;
            }
        }
    }
    .windows-home-title-img {
        width: 250px;
        height: 250px;
    }
    .rotating-image {
        max-width: 250px;
    }
    
}

@media (max-width: 560px) {
    .windows-home{
        h2{
            font-size: 25px;
        }
        .windows-home-title{
            margin-top: 20px;
            border-top: 4px solid black;
            grid-template-columns: 1fr;
            padding: 20px 0;
            grid-template-areas: "windows-home-title-img" "home-title-h";
            height: 350px;
            .windows-home-title-img{
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;
            }
        }
    }
}

@media (max-width: 360px) {
    .windows-home{
        .windows-home-title{
            height: 220px;
            img{
                max-width: 150px;
            }
        }
    }
    .windows-home-title-img {
        width: 150px;
        height: 150px;
    }
    
    .rotating-image {
        max-width: 150px;
    }
}