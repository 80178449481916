.section-forms-register{
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 500px;
    .form-title{
        font-size: 4vw;
        text-align: center;
    }
    .form-text{
        text-align: justify;
        font-size: 20px;
        padding: 30px 0;
    }
    .form-icon{
        display: grid;
        justify-items: center;
        align-items: center;
        svg{
            font-size: 4vw;
        }
    }
    .form-text2{
        text-align: center;
        font-size: 20px;
        padding: 30px 0;
    }
    .input-date{
        display: flex;
        flex-direction: column;
        grid-gap: 5px;
        margin-top: 10px;
        input{
            font-size: 16px;
            font-family: 'Inconsolata', monospace;
            padding: 5px 10px;
            border-radius: 0;
            border: 2px solid black;
            background: rgba(255, 255, 255, 0.767);
            &:focus, &:active {
                outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            }
        }
        label{
            font-size: 14px;
            font-weight: 800;
        }
    }
    .text-date{
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        textarea{
            font-size: 16px;
            font-family: 'Inconsolata', monospace;
            padding: 5px 10px;
            border-radius: 0;
            border: 2px solid black;
            resize: vertical; 
            max-height: 40vh;
            min-height: 30vh;
            background: rgba(255, 255, 255, 0.767);
            &:focus, &:active {
                outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
            }
        }
        label{
            font-size: 14px;
            font-weight: 800;
        }
    }
    .button-form{
        border: 2px solid black;
        background: black;
        color: white;
        font-size: 20px;
        width: 100%;
        padding: 5px 10px;
        margin-top: 10px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center; 
        text-decoration: none;       
        &:hover{
            border: 2px solid black;
            color: black;
            background: none;
        }
    }

    .button-dow{
        border: 2px solid black;
       background: black;
       color: white;
       font-size: 20px;
       width: 90%;
    padding: 5px 5%;
       margin-top: 10px;
       cursor: pointer;
       display: flex;
       justify-content: center;
       align-items: center; 
       text-decoration: none;       
       &:hover{
           border: 2px solid black;
           color: black;
           background: none;
       }
       svg{
           margin-right: 10px;
       }
   }
}

@media (max-width: 1400px) {
    .section-forms-register{
        .form-title{
            font-size: 35px;
        }
        .form-text{
            font-size: 16px;
        }
    }
}

@media (max-width: 760px) {
    .section-forms-register{
        .input-date{
            input{
                font-size: 14px;
            }
            label{
                font-size: 12px;
            }
        }
        .text-date{
            textarea{
                font-size: 14px;
                &:focus, &:active {
                    outline: none; /* Agregado para eliminar el borde al enfocarse o activarse */
                }
            }
            label{
                font-size: 12px;
                margin-bottom: 5px;
            }
        }
    }
}