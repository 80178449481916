
.footer{
    padding: 20px 10% 10px 10%;
    width: 80%;
    h3{
        font-size: 1.5vw;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-bottom: 20px;
    }
    .footer-info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .footer-column{
            display: grid;
            justify-items: start;
            margin-bottom: 20px;
            button{
                border: none;
                background: none;
                display: flex;
                align-items: center;
                padding: 5px 0;
                text-align: start;
                font-size: 18px;
                cursor: pointer;
                svg{
                    font-size: 20px;
                    margin-right: 10px;
                    cursor: pointer;
                }
                p{
                    font-size: 20px;
                    cursor: pointer;
                }
            }
        }
    }
    .footer-home{
        display: grid;
        justify-items: center;
        button{
            border: none;
            background: none;
            display: flex;
            align-items: center;
            padding: 5px 0;
            text-align: start;
            font-size: 18px;
            cursor: pointer;
            svg{
                font-size: 20px;
                margin-right: 10px;
                cursor: pointer;
            }
            p{
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
    .copy-rights{
        border-top: 4px solid black;
        padding-top: 10px;
        p{
            font-size: 14px;
            text-align: center;
        }
    }
}


@media (max-width: 1400px) {
    .footer{
        padding: 10px 5% 10px 5%;
        width: 90%;
        h3{
            font-size: 22px;
            margin-bottom: 10px;
        }
        .footer-info{
            .footer-column{
                margin-bottom: 10px;
                button{
                    font-size: 16px;
                    svg{
                        font-size: 16px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
            }
        }
        .footer-home{
            button{
                font-size: 16px;
                svg{
                    font-size: 16px;
                }
                p{
                    font-size: 16px;
                }
            }
        }
        .copy-rights{
            p{
                font-size: 10px;
            }
        }
    }    
}


@media (max-width: 580px) {
    .footer{
        h3{
            font-size: 18px;
            margin-bottom: 10px;
        }
        .footer-info{
            grid-template-columns: 1fr;
            .footer-column{
                margin-bottom: 10px;
                button{
                    font-size: 14px;
                    svg{
                        font-size: 14px;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
        .footer-home{
            button{
                font-size: 14px;
                svg{
                    font-size: 14px;
                }
                p{
                    font-size: 14px;
                }
            }
        }
    }  
}