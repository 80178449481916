@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700&display=swap');

body {
  font-family: 'Inconsolata', monospace !important;;
  background: #eeeeee !important;
  color: black;
  padding: 0 !important;
  margin: 0 !important;
}


p {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  unicode-bidi: isolate;
  font-size: 18px;
}

@media (max-width: 1400px) {
  p {
    font-size: 16px;
  }
}

@media (max-width: 560px) {
  p {
    font-size: 14px;
  }
}

h1 {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  unicode-bidi: isolate;
}

h2 {
  display: block;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  unicode-bidi: isolate;
}

h4 {
  font-size: 35px;
  text-align: center;
  display: block;
  margin-block-start: 0.5em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  unicode-bidi: isolate;
}

@media (max-width: 1400px) {
  h4 {
    font-size: 20px;
  }
}

@media (max-width: 560px) {
  h4 {
    font-size: 18px;
  }
}

button{
  font-family: 'Inconsolata', monospace !important;;
}

.body-page{
  display:flex;
  flex-direction: column;
}

.navbar-title {
  text-align: left; // Estilo por defecto para otras rutas
  &.center-title {
      text-align: center; // Estilo centrado solo para la ruta "/"
  }
}
