.pop-pup{
    width: 100%;
    height: 100vh;
    z-index: 9999;
    position: fixed;
    backdrop-filter: blur(30px) saturate(58%);
    -webkit-backdrop-filter: blur(30px) saturate(58%);
    background-color: rgba(236, 236, 236, 0.77);
    display: grid;
    justify-items: center;
    align-items: center;
}

.close-button{
    position: fixed;
    top: 40px;
    right: 40px;
    font-size: 50px;
    background: none;
    border: none;
    opacity: 1;
    &:hover{
        opacity: 0.5;
        cursor: pointer;
    }
}


@media (max-width: 560px) {
    .close-button{
        top: 20px;
        right: 20px;
        font-size: 30px;
    }
    
}